<template>
  <div>
    <div class="ps-2">
      <HeaderPage />
    </div>
    <SeeMorePage
      :title="'お気に入り店舗'"
      :data_list="favStore"
      :data_type="'store'"
    />
    <v-pagination
      v-if="!isLength"
      color="#ff0090"
      v-model="pageOrder"
      :total-visible="5"
      :length="favStoreHeader.last_page"
      @input="nextPageOrder"
      style="margin-top: 10px"
    ></v-pagination>
    <Cashier />
    <Sidebar />
  </div>
</template>
<script>
import SeeMorePage from "../components/SeeMorePage.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Cashier from "../components/Cashier.vue";

export default {
  name: "ViewAllFavoriteStore",
  components: {
    SeeMorePage,
    HeaderPage,
    Sidebar,
    Cashier,
  },
  data() {
    return {
      isLoadingFavStore: true,
      pageOrder: 1,
    };
  },
  methods: {
    async getFavStore() {
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        this.isLoadingFavStore = true;
        let res = await this.$store.dispatch("shop_module/fetchStoreFav", {
          page: this.pageOrder,
        });
        this.isLoadingFavStore = false;
        return res;
      }
    },
    nextPageOrder() {
      this.$store.dispatch("shop_module/fetchStoreFav", {
        page: this.pageOrder,
      });
    },
  },
  computed: {
    isLength() {
      return this.$store.state.shop_module.is_length;
    },
    favStore() {
      return this.$store.state.shop_module.storeFav;
    },
    favStoreHeader() {
      return this.$store.state.shop_module.storeFavHeader;
    },
  },
  created() {
    this.getFavStore();
  },
};
</script>
